:root {
  --primary-light-color: #fff;
  --secondary-color: #d3d4d5;
  --primary-dark-color: #212529;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.light {
  color: var(--primary-dark-color);
  background-color: var(--primary-light-color);
}
.dark {
  color: var(--primary-light-color);
  background-color: var(--primary-dark-color);
}
