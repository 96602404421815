.Socialmedia-countainer-light {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  left: 15px;
  bottom: 100px;
}
.Socialmedia-countainer-light::after {
  content: "";
  width: 2px;
  height: 50px;
  background-color: var(--primary-dark-color);
}

.Socialmedia-countainer-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: fixed;
  left: 15px;
  bottom: 100px;
}
.Socialmedia-countainer-dark::after {
  content: "";
  width: 2px;
  height: 50px;
  background-color: var(--primary-light-color);
}

.Socialmedia a {
  color: var(--primary-dark-color);
  transition: all 300ms ease-in-out;
}
.Socialmedia a:hover {
  color: var(--secondary-color);
}
.theme-icon:hover {
  color: var(--secondary-color);
  transition: 2s;
  transform: rotate(360deg);
}
.Socialmedia-countainer-dark a {
  color: var(--primary-light-color);
  transition: all 300ms ease-in-out;
}
.Socialmedia-countainer-dark a:hover {
  color: var(--secondary-color);
}
