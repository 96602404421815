.App {
  padding: 50px 0 50px 0;
  max-width: 800px;
  margin: 0 auto;
}
.App H1,
.App h2 {
  font-family: "Montserrat", sans-serif;
}

.App H1 {
  font-size: 80px;
}
.App h2 {
  font-size: 40px;
}

.App p {
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
}
