.Project {
  padding: 100px 0 50px 0;
}
.project-title {
  padding: 0 0 30px 0;
}
.project-row {
  margin: 10px;
  align-items: center;
}
