.Profile {
  padding: 150px 0 150px 0;
}
.Profile ul {
  padding-left: 0;
}
.Profile li {
  display: inline-block;
  list-style-type: none;
  margin: 3px;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 13px;
}

.Profile .light li {
  border: 1px var(--primary-dark-color) solid;
}

.Profile .dark li {
  border: 1px var(--primary-light-color) solid;
}
