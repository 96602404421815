.Motion {
  margin: 20px auto;
  padding: 100px 0 100px 0;
}
.Motion .circle {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Motion .dark {
  background-color: var(--primary-dark-color);
}
.Motion .light {
  background-color: var(--primary-light-color);
}
